import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Constants
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AppConfigConstants } from '../../common/constants';

// Services
import { CognitoService } from '../../core/services/cognito.service';
import { AuthService } from '../../core/services/auth.service';
import { StorageService } from '../../core/services/storage.service';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { ValidationService } from '../../core/services/validation.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppToasterService } from 'src/app/core/services/app-toaster.service';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  title = 'login';
  apiErrorMsg = '';
  loginFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  forcePasswordChange = false;
  showPassword = false;
  version = VERSION.version;
  changePswdView = false;

  constructor(
    private cognitoService: CognitoService,
    private authService: AuthService,
    private builder: FormBuilder,
    private router: Router,
    private storage: StorageService,
    private spinnerService: SpinnerService,
    private validationService: ValidationService,
    private cookieService: CookieService,
    private permissionsService: NgxPermissionsService,
    private apptoaster: AppToasterService
  ) { }

  ngOnInit() {
    this.initLoginForm();
    this.initChangePasswordForm();
  }

  initLoginForm() {
    this.loginFormGroup = this.builder.group({
      'email': new FormControl(this.cookieService.get('email') ? this.cookieService.get('email') : '',
        [Validators.required, this.validationService.emailValidator, this.validationService.hasWhiteSpace]),
      'password': new FormControl(this.cookieService.get('password') ? this.cookieService.get('password') : '',
        [Validators.required, this.validationService.hasWhiteSpace]),
      'remember': new FormControl(this.cookieService.get('remember') ? this.cookieService.get('remember') : false)
    });
  }

  initChangePasswordForm() {
    this.changePasswordFormGroup = this.builder.group({
      'newPassword': new FormControl('', [
        Validators.required,
        this.validationService.noWhiteSpace, Validators.minLength(8),
        this.validationService.validPassword,
        this.validationService.passwordValidator,
        this.validationService.confirmPasswordValidation('confirmPassword', true)
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
        this.validationService.confirmPasswordValidation('newPassword', false)
      ]),
    });
  }

  login() {
    if (this.validationService.validateForm(this.loginFormGroup)) {
      const loginForm = this.loginFormGroup.getRawValue();
      this.spinnerService.change(true);
      this.cognitoService.authenticate(loginForm['email'], loginForm['password'], '')
        .then(this.loggedIn, this.logInError);

    }
  }

  changePassword() {
    if (this.validationService.validateForm(this.changePasswordFormGroup)) {
      const loginForm = this.loginFormGroup.getRawValue();
      const changePasswordForm = this.changePasswordFormGroup.getRawValue();
      this.spinnerService.change(true);
      this.changePswdView = true;
      this.cognitoService.authenticate(loginForm['email'], loginForm['password'], changePasswordForm['newPassword'])
        .then(this.loggedIn, this.logInError);
    }
  }

  loggedIn = (response: CognitoUserSession) => {
    if (this.loginFormGroup.value.remember) {
      this.cookieService.set('email', this.loginFormGroup.value.email);
      this.cookieService.set('password', this.loginFormGroup.value.password);
      this.cookieService.set('remember', this.loginFormGroup.value.remember);
    } else {
      this.cookieService.delete('email');
      this.cookieService.delete('password');
      this.cookieService.delete('remember');
    }
    const authServiceObservable = this.authService.getClientData()
      .subscribe(res => {
        if (res.type !== 'RE User') {
          if (res.groupRole !== null && res.groupRole !== '' && res.groupRole.policies && res.groupRole.policies.length > 0) {
            const policies = res.groupRole.policies.map(o => {
              return o.name;
            });
            const teams = res.team.map(team => team.teamUsers.find(x => x.userId === res.id));
            teams.map(teamusers => { if (teamusers.role.policies) { teamusers.role.policies.map(p => { policies.push(p.name); }); } });
            this.storage.setObject('policies', policies);
            this.permissionsService.addPermission(policies);
          }
          // this.spinnerService.change(false);
          this.storage.setObject(AppConfigConstants.clientData, res);

          this.authService.clientId = res.clientId;
          this.router.navigate(['/dashboard']);
          authServiceObservable.unsubscribe();
        } else {
          // this.spinnerService.change(false);
          this.apptoaster.showError('Sorry You Are Not Registered To View This Page');
          this.loginFormGroup.controls.email.setValue('');
          this.loginFormGroup.controls.password.setValue('');
        }
        const clientMetaDataObservable = this.authService.getClientMetaData()
          .subscribe(clientMetadata => {
            if (clientMetadata.policies && clientMetadata.policies.length > 0) {
              const clientPolicies = clientMetadata.policies.map(o => {
                return o.name;
              });
              this.storage.setObject('clientPolicies', clientPolicies);
            }
            this.spinnerService.change(false);
            clientMetaDataObservable.unsubscribe();
        }, err => {
          this.spinnerService.change(false);
        });
      }, err => {
        this.spinnerService.change(false);
      });
  }

  logInError = (err: Error) => {
    this.spinnerService.change(false);
    if (err.name === AppConfigConstants.forcePasswordChange) {
      this.forcePasswordChange = true;
    } else if (err.name === AppConfigConstants.cognitoErrorResponses.userNotFoundException) {
      this.apiErrorMsg = 'Wrong email and/or password';
    } else if (err.name === AppConfigConstants.cognitoErrorResponses.notAuthorizedException) {
      if (err.message.indexOf('username') > -1) {
        this.apiErrorMsg = 'Incorrect email or password.';
      } else {
        this.apiErrorMsg = err.message;
      }
    } else {
      this.apiErrorMsg = err.message;
    }
  }

  changePasswordType() {
    this.showPassword = !this.showPassword;
  }
}
