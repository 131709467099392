import { Injectable } from '@angular/core';
import { ValidationConfig, ValidationError } from '../../common/interfaces';
import { FormArray, FormGroup, ValidatorFn, FormControl } from '@angular/forms';

// this is going to be refactored
@Injectable()
export class ValidationService {

  constructor() { }

  getValidatorErrorMessage(label: string, validatorName: string, validatorValue?: any) {
    const config: ValidationConfig = {
      'required': ((label !== '') ? label + ' is required' : 'Field is required'),
      'whiteSpace': 'White spaces not allowed',
      'email': ((label !== '') ? label + ' Format is incorrect' : 'Format is incorrect'),
      'maxlength': 'Maximum length allowed is ' + validatorValue.requiredLength + ' characters',
      'minlength': 'Minimum length allowed is ' + validatorValue.requiredLength + ' characters',
      'invalidAlphabet': 'Enter only alphabet character in ' + label,
      'invalidNumber': 'Enter only numbers in ' + label,
      'invalidPhone': 'only + at beginning and numbers are allowed',
      'appValidateEqual': 'Password does not match',
      'invalidEmailAddress': 'Enter valid email address',
      'invalidPassword': `Password must be atleast 8 character long and should have one uppercase,
                          one lowercase, one special character & a number`,
      'invalidGreaterDate': label + ' must be equal or greater than current date',
      'noWhiteSpace': 'Please don\'t begin or end your password with blank space',
      'invalidNewpswd': 'New password should be different from old password ',
      'invalidName': 'Enter only alphabets, numbers and underscore ' + label
    };
    return config[validatorName];
  }

  validateForm(form) {
    for (const name in form.controls) {
      if (form.controls[name] instanceof FormArray) {
        form.controls[name].controls.forEach((formGroup: FormGroup) => {
          this.validateForm(formGroup);
        });
        this.validateForm(form.controls[name]);
      } else if (form.controls[name] instanceof FormGroup) {
        this.validateForm(form.controls[name]);
      } else if (form.controls[name] && form.controls[name].enabled) {
        form.controls[name].updateValueAndValidity();
        form.controls[name].markAsTouched();
      }
    }
    return form.valid;
  }

  resetForm(form) {
    for (const name of form.controls) {
      form.controls[name].setValue('');
      form.controls[name].setErrors(null);
    }
  }

  hasWhiteSpace(control): ValidationError {
    if (/^\s*$/.test(control.value) === false) {
      return null;
    }
    return { 'required': true };
  }

  noWhiteSpace(control): ValidationError {
    if (/^[!@#$%^&*(),.?":{}|<>|\w]+(.+[!@#$%^&*(),.?":{}|<>|\w])*$/.test(control.value) === true) {
      return null;
    }
    return { 'noWhiteSpace': true };
  }

  validPassword(control): ValidationError {
    if (/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]).*$/.test(control.value) === true) {
      return null;
    }
    return { 'invalidPassword': true };
  }

  containsWhiteSpace(control): ValidationError {
    return /^\s+$/.test(control.value) ? { 'whiteSpace': true } : null;
  }

  allowAlphabet(control): ValidationError {
    if (/^[A-Za-z\s]+$/.test(control.value)) {
      return null;
    }
    return { 'invalidAlphabet': true };
  }

  allowNumeric(control): ValidationError {
    if (/^\d+$/.test(control.value)) {
      return null;
    }
    return { 'invalidNumber': true };
  }

  allowPhone(control): ValidationError {
    if (/^(?:[+]?\d+|\d)$/.test(control.value)) {
      return null;
    }
    return { 'invalidPhone': true };
  }

  allowPhone_with_emptyValue(control): ValidationError {
    if (control.value) {
      if (/^(?:[+]?\d+|\d)$/.test(control.value)) {
        return null;
      }
      return { 'invalidPhone': true };
    }
    return null;
  }

  checkBoxValidation(control): ValidationError {
    if (control.value.includes(true)) {
      return null;
    }
    return { 'required': true };
  }
  newPasswordValidation(control): ValidationError {
    const parent = control._parent as FormGroup;
    if (parent) {
      return parent.controls.oldPassword.value === control.value ? { 'invalidNewpswd': true } : null;
    }
  }
  confirmPswdValdiation(control): ValidationError {
    const parent = control._parent as FormGroup;
    if (parent) {
      return parent.controls.newPassword.value === control.value ? null : { 'appValidateEqual': true };
    }
  }
  confirmPasswordValidation(appValidateEqual: string, isReverse: boolean): ValidatorFn {
    return (formControl: FormControl): { [key: string]: any } | null => {
      const currentVal = formControl.value;
      const passwordControl = formControl.root.get(appValidateEqual);
      if (passwordControl && currentVal !== passwordControl.value && !isReverse) {
        return {
          appValidateEqual: true
        };
      }

      if (passwordControl && currentVal === passwordControl.value && isReverse) {
        if (passwordControl.errors) {
          delete passwordControl.errors['appValidateEqual'];
          if (!Object.keys(passwordControl.errors).length) {
            passwordControl.setErrors(null);
          }
        }
      }


      if (passwordControl && currentVal !== passwordControl.value && isReverse) {
        passwordControl.setErrors({ appValidateEqual: true }, { emitEvent: true });
      }
      return null;
    };
  }

  isBlank(value) {
    return (typeof value === 'undefined' || !value) ? true : false;
  }

  isString(value) {
    return (typeof value === 'string') ? true : false;
  }

  emailValidator = (control) => {
    // RFC 2822 compliant regex
    if (control.hasError('required') ||
      (this.isBlank(control.value) || (this.isString(control.value) && control.value.trim() === ''))) {
      return null;
    }
    if (control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) { /* tslint:disable */
      return null;
    }
    return { 'invalidEmailAddress': true };

  }

  passwordValidator = (control) => {
    // RFC 2822 compliant regex
    if (control.hasError('required') ||
      (this.isBlank(control.value) || (this.isString(control.value) && control.value.trim() === ''))) {
      return null;
    }
    if (control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\$^*.\[\]{}\(\)\?\-\"!@#%&,><\':;\|_~`\/\\])(?=.{8,})/)) { /* tslint:disable */
      return null;
    }
    return { 'invalidPassword': true };
  }

  dateGreaterThanToday = (control) => {
    const { year, month, day } = control.value;
    const controlDate = new Date(`${year}-${month}-${day}`).getTime();
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    if (controlDate >= todaysDate) {
      return null;
    }
    return { 'invalidGreaterDate': true };
  }

  allowAlphabetandNumber(control): ValidationError {
    if (/^(?![0-9._])[a-zA-Z0-9_]+$/.test(control.value)) {
      return null;
    }
    return { 'invalidName': true };
  }
}
