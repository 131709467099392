import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

// Services
import { SpinnerService } from './core/spinner/spinner.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { StorageService } from './core/services/storage.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  policies = [];

  showSpinner: boolean;
  constructor(
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    private storage: StorageService,
    private updates: SwUpdate
  ) {
    this.checkUpdates();
  }

  ngOnInit() {
    this.spinnerService.showSpinner()
      .subscribe(status => {
        this.showSpinner = status;
        this.changeDetectorRef.detectChanges();
      });
    // this.policies = ['UserManagementFullAccess', 'UserManagementReadAccess', 'SiteManagementFullAccess',
    //   'SiteManagementReadAccess', 'ChecklistManagementFullAccess', 'ChecklistManagementReadAccess', 'ReferenceMaterialFullAccess',
    //   'ReferenceMaterialReadAccess', 'InspectionReviewFullAccess', 'InspectionAssignmentFullAccess', 'ClaimInspectionFullAccess',
    //   'ConductInspectionFullAccess', 'PassInspectionFullAccess', 'IssueWarningFullAccess', 'IssueNovFullAccess', 'IssueOrderFullAccess',
    //   'VideoConferencingFullAccess'];
    // this.policies = ['UserManagementFullAccess', 'UserManagementReadAccess', 'SiteManagementFullAccess', 'RolesFullAccess',
    //   'TeamsFullAccess', 'UserFullAccess', 'RolesReadOnlyAccess', 'TeamsReadOnlyAccess', 'UserReadOnlyAccess',
    //   'SiteManagementReadAccess', 'ChecklistManagementFullAccess', 'ChecklistManagementReadAccess', 'InspectionReviewFullAccess',
    //   'InspectionAssignmentFullAccess', 'ClaimInspectionFullAccess', 'ConductInspectionFullAccess', 'PassInspectionFullAccess',
    //   'IssueWarningFullAccess', 'IssueNovFullAccess', 'IssueOrderFullAccess', 'VideoConferencingFullAccess',
    //   'ReferenceMaterialFullAccess', 'ReferenceMaterialReadAccess'];



    // this.storage.setObject('policies', this.policies);
    if (this.storage.getItem('policies')) {
      this.policies = this.storage.getObject('policies');
    }
    this.permission();
  }
  permission() {
    this.permissionsService.loadPermissions(this.policies);
  }

  checkUpdates() {
    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => {
        document.location.reload();
      });
    });
  }
}
