import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortHeader, QueryParams } from '../../common/interfaces';
import { ChecklistManagementService } from '../checklist-management.service';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { ModalService } from '../../common/modal/modal.service';
import { Penalty, Violation, CorrectiveAction, ChecklistItem } from '../checklist-management.interface';
import { AppConfigConstants } from '../../common/constants';



@Component({
  selector: 'app-violations',
  templateUrl: './violations.component.html',
  styleUrls: ['./violations.component.scss']
})
export class ViolationsComponent implements OnInit {
  checklistItem: ChecklistItem;
  violations: Array<Violation>;
  tableListheader: Array<SortHeader>;
  violationForm: FormGroup;
  params: QueryParams;
  correctiveActions: Array<CorrectiveAction>;
  penalty_form: boolean;
  correctiveValue: any;
  violation: Violation;
  validationService: any;
  modalRef: any;
  correctiveDescription: boolean;
  total_site_item: number;
  pageNumber: any = 1;
  items_per_page: any = AppConfigConstants.pageSize;
  currencies: Array<any>;
  currentSelection = true;
  name: string;
  isList: boolean;
  isCreate: boolean;
  loading: boolean;

  constructor(public activeModal: NgbActiveModal,
    private checklistmanagementservice: ChecklistManagementService,
    private modalservice: ModalService,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder) {
    this.penalty_form = false;
    this.correctiveDescription = false;
    this.name = 'Add';
    this.isList = true;
    this.isCreate = false;

  }
  ngOnInit() {
    this.initializeData();
    this.violationInitialize(null);
    this.getData();
  }
  onCheck(violation: Violation) {
    if (this.checklistItem && this.checklistItem.violation) {
      return (violation.id === this.checklistItem.violation.id) ? true : false;
    } else {
      return false;
    }

  }
  initializeData() {
    this.currencies = [{ 'name': 'USD' }, { 'name': 'EUR' }, { 'name': 'AED' }, { 'name': 'INR' }, { 'name': 'CAD' }];
    this.tableListheader = [
      { displayName: '' },
      { displayName: 'Description', sortBy: 'description', filterable: true },
      { displayName: 'Citation' },
      { displayName: 'Corrective Actions', sortBy: 'correctiveActions', filterable: true },
      { displayName: 'Penalty' },
      { displayName: '' }
    ];
    this.params = {
      pageSize: AppConfigConstants.pageSize,
      offset: 0,
      sortBy: 'name',
      sortOrder: 'desc',
      search: '',
      association: 'associated',
      status: 'active'
    };
  }

  violationInitialize(violation) {
    this.violationForm = this.formBuilder.group({
      id: [violation ? violation.id : null],
      description: [violation ? violation.description : '', [Validators.required, Validators.maxLength(255)]],
      citation: [violation ? violation.citation : '', [Validators.required, Validators.maxLength(255)]],
      isActive: true,
      correctiveActions: [violation ? violation.correctiveActions : [], []],
      penalty: this.getPenalty(violation ? violation.penalty : null),
    });
  }
  getPenalty(penalty: Penalty): FormGroup {
    const formgroup: FormGroup = this.formBuilder.group({
      description: [penalty ? penalty.description : '', [Validators.required, Validators.maxLength(255)]],
      amount: [penalty ? penalty.amount : '', [Validators.required, Validators.maxLength(10)]],
      currency: [penalty ? penalty.currency : '', [Validators.required, Validators.maxLength(10)]],
      isActive: [true]
    });
    return formgroup;
  }

  getData() {
    this.spinnerService.change(true);
    this.loading = true;
    this.checklistmanagementservice.getViolations(this.params).subscribe(response => {
      this.total_site_item = response.meta.total;
      this.correctiveActions = [].concat(...(response.data.violationDefs.map(i => i.correctiveActions)),
        this.violations = <Array<Violation>>response.data.violationDefs);
      this.loading = false;
      this.spinnerService.change(false);
    });

  }



  onSelect(violation) {
    this.violation = violation;
  }

  saveViolation() {
    let violationData: Violation;
    if (this.isList) {
      this.violation.checklistItems = [];
      this.violation.checklistItemDefIds.push(this.checklistItem.id);
      this.violation.checklistItems.push(this.checklistItem);
      violationData = this.violation;
    }
    if (this.isCreate) {
      const violation = this.violationForm.getRawValue();
      violation.checklistItems = [];
      violation.checklistItemDefIds = [];
      violation.checklistItemDefIds.push(this.checklistItem.id);
      violation.checklistItems.push(this.checklistItem);
      if (typeof (violation.correctiveActions) === 'string') {
        violation.correctiveActions = [violation.correctiveActions];
      }
      violationData = violation;
    }
    this.spinnerService.change(true);
    // const violation = this.violationForm.getRawValue();
    // this.violation.externalId = '123'; // Should be removed
    // this.violation.penalty.externalId = '123'; // Should be removed

    // this.spinnerService.change(false);

    // this.violation.checklistItems.push(this.checklistItem);

    this.checklistmanagementservice.addViolation(violationData).subscribe(response => {
      this.spinnerService.change(false);
      this.closeModal(response);
    }, error => {
      this.spinnerService.change(false);
    });
  }

  toggleClick(toggleObj: any) {
    const value = toggleObj.show;
    this.checklistmanagementservice.hideOtherListItems(this.violations);
    toggleObj.show = value ? false : true;
  }

  addNewCorrectiveAction() {
    this.correctiveDescription = true;
  }

  dismissModal() {
    this.activeModal.dismiss('DISMISSED !');
  }

  addPenalty() {
    this.penalty_form = true;
  }

  onSortChange(sortItem: any) {
    this.getData();
  }

  // onFilterChange(value: string) {
  //   if (!value) {
  //     delete this.params.filterBy;
  //     if (this.params.search.length) {
  //       this.getData();
  //     }
  //   } else if (this.params.search.length) {
  //     this.getData();
  //   }
  // }


  pageOnChange(event) {
    this.pageNumber = event;
    this.params.offset = (this.pageNumber - 1) * this.items_per_page;
    this.getData();
  }


  onSearch(searchString: string) {
    this.params.search = searchString;
    this.getData();
  }

  closeModal(data: any) {
    this.activeModal.close(data);
  }
  changeEvent() {
    this.isCreate = !this.isCreate;
    this.isList = !this.isList;
    this.name = this.isCreate ? 'Create' : 'Add';
    this.violationInitialize(null);
  }

  editViolation(violation: Violation) {
    this.violationInitialize(violation);
    this.isCreate = !this.isCreate;
    this.isList = !this.isList;
    this.name = 'Edit';
  }

  onscrolle() {
    console.log('scrolle');
  }
  ondivscroll() {
    // this.pageNumber = this.pageNumber + 1;
    // this.params.offset = (this.pageNumber - 1) * this.items_per_page;
    // this.getData();
  }
}

