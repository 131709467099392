export const AppConfigConstants = {
    baseURL: '/api/v1',
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    clientData: 'clientData',
    forcePasswordChange: 'forcePasswordChange',
    pageSize: 10,
    fileTypes: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/bmp',
    ],
    referenceMaterialFileTypes: `application/pdf,
    application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    image/bmp, image/jpeg, image/png,
    text/plain,
    video/mp4, video/x-ms-wmv, audio/x-ms-wma, audio/mp3, application/ogg`,
    cognitoErrorResponses: {
        userNotFoundException: 'UserNotFoundException',
        notAuthorizedException: 'NotAuthorizedException',
        expiredCodeException: 'ExpiredCodeException',
        limitExceededException: 'LimitExceededException',
        invalidPasswordException: 'InvalidPasswordException',
        invalidParameterException: 'InvalidParameterException '

    },
    backendErrorResponses: {
        error: 'Error',
        accountExists: 'User account already exists',
        unauthorized: 'Unauthorized'
    }
};

// application/vnd.ms-word, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
//     application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,
//     application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet

export const Messages = {
    deactiveUserTitle: 'Deactivate User',
    deactiveUserBody: `Are you sure you would like to deactivate this client?`,
    activeUserTitle: 'Activate User',
    activeUserBody: 'Are you sure you would like to activate this client?' +
        'You can always change the status of the client by switching the toggle icon.',
    serverError: 'Server Error, Something went wrong!',
    deleteReferenceMaterialTitle: 'Delete Reference Material',
    deleteReferenceMaterialBody: 'Are you sure you would like to delete this reference material? ',
    fileTypeError: 'File Format Not Supported',
    videoChatTitle: 'Video Call',
    videoChatMessge: (callerName) => `Call from ${callerName}`,
    rejectInspectionReviewTitle: 'Reject Inspection',
    rejectInspectionReviewBody: 'Are you sure you want to reject this inspection?',
    deleteRoleTitle: 'Delete Role',
    deleteRoleBody: ` Deleting this role could affect user
                      assignments and possibly removal of users
                      from teams. Are you sure you want to
                      delete it?`,
    deleteTeamTitle: 'Delete Team',
    deleteTeamBody: ` Are you sure you want to delete the
                      team?`,
    deleteUserTitle: 'Delete User',
    deleteUserBody: `Are you sure you want to delete the
                      user?`,
    uploadSameFile: `This file is already Uploaded. Please Upload another`,
    teamHasClaims: 'You are trying to delete a team in which user has active inspection assignments.' +
        'Please remove the assignments and try again.',
    userHasClaims: 'You are trying to remove a user with active inspection assignments. Please remove the assignments and try again.',
    cannotDeleteUser: 'You are trying to delete a user with active inspection assignments.' +
        'Please remove the assignments and try again.',
    cannotDeactivateUSer: 'You are trying to deactivate a user with active inspection assignments.' +
        'Please remove the assignments and try again.'
};

export const ExceptionMessages = {
    notAuthorizedException: 'Entered old password is incorrect',
    limitExceededException: 'Maximum number of attepmts reached , please try after some time',
    invalidPasswordException: 'Please include atleast 1 uppercase, 1 lowercase characters, 1 number and 1 special character',
    invalidParameterException: 'Password must have length greater than or equal to 6'
};

export const UserComponentMesages = {
    addUserMessage: 'User created successfully.',
    updateUserMessage: 'You have just updated an account for {{username}}.',
    deleteUserMessage: 'User has been deleted',
    activateUserMessage: 'User Successfully Activated',
    deactivateUserMessage: 'User Successfully Deactivated',
};

export const RoleComponentMesages = {
    addRoleMessage: 'Role Successfully Saved',
    updateRoleMessage: 'Role Successfully Updated',
    deleteRoleMessage: 'Role has been Deleted'
};

export const TeamComponentMesages = {
    addTeamMessage: 'Team Successfully Saved',
    updateTeamMessage: 'Team Successfully Updated',
    deleteTeamMessage: 'Team has been deleted',
    existTeamMessage: 'Team Name Already Exists'
};

export const ForgotPasswordComponentMessages = {
    emailSent: 'Please check your email to proceed further'
};

export const ResetPasswordComponentMessages = {
    success: 'Password has been reset. Please login with new credentials'
};

export const ReferenceMaterialMessages = {
    addReferenceMaterialMessage: 'Reference Material Successfully Saved',
    updateReferenceMaterialMessage: 'Reference Material Successfully Updated',
    deleteReferenceMaterialMessage: 'Reference Material Deleted Successfully'
};

export const SiteMessages = {
    deactivateModalTitle: 'Deactivate Site',
    deactivateModalBody: 'Are you sure you would like to deactivate this site? ' +
        'You can always change the status of the site by switching the toggle icon.',
    activateModalTitle: 'Activate Site',
    activateModalBody: 'Are you sure you would like to activate this site? ' +
        'You can always change the status of the site by switching the toggle icon.',
    deleteTitle: 'Delete Site',
    deleteBody: 'Are you sure you would like to delete this site? ',
    activateMessage: 'Site Successfully Activated',
    deactivateMessage: 'Site Successfully Deactivated',
};
export const ChecklistMessages = {
    deactivateModalTitle: 'Deactivate Section',
    deactivateModalBody: 'Are you sure you would like to deactivate this section? ' +
        'You can always change the status of the section by switching the toggle icon.',
    activateModalTitle: 'Activate Section',
    activateModalBody: 'Are you sure you would like to activate this section? ' +
        'You can always change the status of the section by switching the toggle icon.',
    deleteSectionTitle: 'Delete Section',
    deleteSectionBody: 'Are you sure you would like to delete this section? ',
    deleteChecklistTitle: 'Delete Checklist',
    deleteChecklistBody: 'Are you sure you would like to delete this checklist? ',
    deleteChecklistItemTitle: 'Delete ChecklistItem',
    deleteChecklistItemBody: 'Are you sure you would like to delete this checklistItem? ',
    activateMessage: 'Section Successfully Activated',
    deactivateMessage: 'Section Successfully Deactivated',
};

export const ActionableSitesMessages = {
    deactivateModalTitle: 'Deactivate Actionable Site',
    deactivateModalBody: 'Are you sure you would like to deactivate this actionable site? ' +
        'You can always change the status of the actionable site by switching the toggle icon.',
    activateModalTitle: 'Activate Actionable Site',
    activateModalBody: 'Are you sure you would like to activate this actionable site? ' +
        'You can always change the status of the actionable site by switching the toggle icon.',
    deleteTitle: 'Delete Actionable Site',
    deleteBody: 'Are you sure you would like to delete this actionable site? ',
    activateMessage: 'Actionable Site Successfully Activated',
    deactivateMessage: 'Actionable Site Successfully Deactivated',
    deletePreviousInspection: 'Deleting this inspection will not delete the associated claim.' +
        'Are you sure you want to delete this Inspection?'
};

export const ActionableSiteItemsMessages = {
    deactivateModalTitle: 'Deactivate Actionable Item',
    deactivateModalBody: 'Are you sure you would like to deactivate this actionable site item? ' +
        'You can always change the status of the actionable site item by switching the toggle icon.',
    activateModalTitle: 'Activate Actionable Item',
    activateModalBody: 'Are you sure you would like to activate this actionable site item? ' +
        'You can always change the status of the actionable site item by switching the toggle icon.',
    deleteTitle: 'Delete Actionable Item',
    deleteBody: 'Are you sure you would like to delete this actionable site item? ',
    activateMessage: 'Actionable Site Item Successfully Activated',
    deactivateMessage: 'Actionable Site Item Successfully Deactivated',
};

export const AssignmentInspectionMessages = {
    ispectionRemovedMessage: 'Inspection has been Removed!',
    removeConfirmationMesssage: 'Are you sure you would like to remove this inspection assignment?',
    cannotReassign: 'You are reassigning an inspection that has already started. Please contact the Inspector.',
    deleteActionableSite: 'You are removing an assignment for which the inspection has already started. Please contact the Inspector.'
};

export const FollowupMessages = {
    deleteFollowup: 'Are you sure you would like to delete this follow-up?'
};

export function generateMessage(message: string, replaceableArray: string[], toReplace: string[]) {
    replaceableArray.map((replaceableString, i) => {
        message = message.replace(replaceableString, toReplace[i]);
    });
    return message;
}

export const durations = [
    { name: '0.5 h', value: 1800 },
    { name: '1 h', value: 3600 },
    { name: '1.5 h', value: 5400 },
    { name: '2 h', value: 7200 },
    { name: '2.5 h', value: 9000 },
    { name: '3 h', value: 10800 },
    { name: '3.5 h', value: 12600 },
    { name: '4 h', value: 14400 },
    { name: '4.5 h', value: 16200 },
    { name: '5 h', value: 18000 },
    { name: '5.5 h', value: 19800 },
    { name: '6 h', value: 21600 },
    { name: '6.5 h', value: 23400 },
    { name: '7 h', value: 25200 },
    { name: '7.5 h', value: 27000 },
    { name: '8 h', value: 28800 },
];
