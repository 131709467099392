import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { LoginsMap, CognitoIdentityProvider } from 'aws-sdk/clients/cognitoidentity';
import {
  CognitoUserPool, CognitoUser, CognitoUserSession, CognitoUserAttribute, AuthenticationDetails,
  IAuthenticationDetailsData, ISignUpResult, ICognitoUserPoolData
} from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { AppConfigConstants } from '../../common/constants';

/**
 * Cognitor Service to connect with Amazon Cognitor Data Store
 * @export
 * @class CognitoService
 */
@Injectable()
export class CognitoService {
  static firstLogin = false;
  static runningInit = false;

  constructor(
  ) {
    AWS.config.region = environment.region;
  }

  getCognitoParametersForIdConsolidation(idTokenJwt: string): AWS.CognitoIdentityCredentials.CognitoIdentityOptions {
    const url = `cognito-idp.${environment.region.toLowerCase()}.amazonaws.com/${environment.userPoolId}`;
    const logins: LoginsMap = {};
    logins[url] = idTokenJwt;
    const params: AWS.CognitoIdentityCredentials.CognitoIdentityOptions = {
      IdentityPoolId: '',
      Logins: logins
    };
    return params;
  }

  /**
   * Check current status of authenticated user
   * @returns {Promise<boolean>}
   * @memberof CognitoService
   */
  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCurrentUser();
      if (cognitoUser !== null) {
        cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session.isValid());
          }
        });
      } else {
        reject(false);
      }
    });
  }

  authenticate(username: string, password: string, newPassword: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      // Need to provide placeholder keys unless unauthorised user access is enabled for user pool
      const authenticationData: IAuthenticationDetailsData = {
        Username: username.toLowerCase(),
        Password: password,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);

      const userData = {
        Username: username.toLowerCase(),
        Pool: this.getUserPool()
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          const url = `cognito-idp.${environment.region}.amazonaws.com/${environment.userPoolId}`;
          const logins = {};
          logins[url] = session.getIdToken().getJwtToken();
          // Add the User's Id Token to the Cognito credentials login map.
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.identityPoolId, // don't know why we need this
            Logins: logins
          });
          resolve(session);
        },
        onFailure: (err: Error) => {
          reject(err);
        },
        newPasswordRequired: (userAttr, requiredAttr) => {
          if (newPassword) {
            cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttr, {
              onSuccess: (session: CognitoUserSession) => {
                resolve(session);
              },
              onFailure: (err: Error) => {
                reject(err);
              }
            });
          } else {
            const err = {
              name: AppConfigConstants.forcePasswordChange
            };
            reject(err);
          }
        }
      });
    });
  }

  createAccount(username: string, password: string, attrs: any, role: string): Promise<CognitoUser | ISignUpResult> {
    attrs['custom:role'] = role;
    // attrs['custom:TermOfUseVersion'] = '1.01';
    const payload = this.formatAttrs(attrs);
    return new Promise((resolve, reject) => {
      this.getUserPool()
        .signUp(username.toLowerCase(), password, payload, null, (err: Error, result: ISignUpResult) => {
          if (err) {
            return reject(err);
          }
          return resolve(result);
        });
    });
  }

  /**
   * Assign user to the 'Group' in Cognito DataStore
   *
   * @param {string} username
   * @param {string} groupName
   * @param {*} logins
   * @returns {Promise<any>}
   * @memberof CognitoService
   */
  assignUserToGroup(username: string, groupName: string, logins: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username.toLowerCase(),
        UserPoolId: environment.userPoolId,
        GroupName: groupName
      };
      const cognitoToIdentityServiceProvider = this.getCognitoToIdentityServiceProvider();
      cognitoToIdentityServiceProvider.config.region = environment.region;
      cognitoToIdentityServiceProvider.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.identityPoolId,
        Logins: logins
      });

      cognitoToIdentityServiceProvider.adminAddUserToGroup(userData, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  logout(): void {
    if (this.getCurrentUser()) {
      this.getCurrentUser().signOut();
    }
  }

  confirmRegistration(username: string, code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username.toLowerCase(),
        Pool: this.getUserPool()
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(code, false, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  forgotPassword(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username.toLowerCase(),
        Pool: this.getUserPool()
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode() {
          resolve();
        }
      });
    });
  }

  resetPassword(email: string, verificationCode: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email.toLowerCase(),
        Pool: this.getUserPool()
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          this.authenticate(email, password, '').then(res => {
            const cognitouser = this.getCurrentUser(); this.getCurrentUser();
            cognitouser.getSession((err: Error, session: CognitoUserSession) => {
              if (err) {
                console.log(err);
              } else {
                cognitouser.globalSignOut({ onFailure: e => console.log(e), onSuccess: r => console.log('Logout success' + r) });
              }
            });
          },
            err => { console.log(err); });

          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  changePassword(oldPassword: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCurrentUser();
      cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          return reject(err.message);
        } else {
          cognitoUser.changePassword(oldPassword, newPassword, (error: Error, res: any) => {
            if (error) {
              return reject(error);
            }
            return resolve();
          });
        }
      });
    });
  }

  resendConfirmationCode(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username.toLowerCase(),
        Pool: this.getUserPool()
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(result);
        }
      });
    });
  }

  /**
  * This is the method that needs to be called in order to init the aws global creds
  */
  initAwsService(isLoggedIn: boolean, idToken: string) {
    if (CognitoService.runningInit) {
      // Need to make sure I don't get into an infinite loop here, so need to exit if this method is running already
      return;
    }
    CognitoService.runningInit = true;

    // First check if the user is authenticated already
    if (isLoggedIn) {
      this.setupAWS(isLoggedIn, idToken);
    }
  }

  setupAWS(isLoggedIn: boolean, idToken: string): void {
    if (isLoggedIn) {
      this.addCognitoCredentials(idToken);
    }

    CognitoService.runningInit = false;
  }

  addCognitoCredentials(idTokenJwt: string): void {
    const params = this.getCognitoParametersForIdConsolidation(idTokenJwt);
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
  }

  getUserPool(): CognitoUserPool {
    const poolData: ICognitoUserPoolData = {
      UserPoolId: environment.userPoolId,
      ClientId: environment.clientId
    };
    return new CognitoUserPool(poolData);
  }

  getCognitoToIdentityServiceProvider(): any {
    // return new AWS.CognitoIdentityServiceProvider({ apiVersion: '2016-04-18' });
  }

  getCurrentUser(): CognitoUser {
    return this.getUserPool().getCurrentUser();
  }

  getCurrentUserSession(): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      this.getCurrentUser().getSession((err: Error, session: CognitoUserSession) => {
        if (err) {
          return reject(err);
        }
        resolve(session);
      });
    });
  }

  getAccessToken(): Promise<string> {
    return this.getCurrentUserSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          return session.getAccessToken().getJwtToken();
        } else {
          throw Error('Invalid Session');
        }
      });
  }

  getIdToken(): Promise<any> {
    return this.getCurrentUserSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          return session.getIdToken().getJwtToken();
        } else {
          throw Error('Invalid Session');
        }
      });
  }

  getRefreshToken(): Promise<any> {
    return this.getCurrentUserSession()
      .then((session: CognitoUserSession) => {
        if (session.isValid()) {
          return session.getRefreshToken().getToken();
        } else {
          throw Error('Invalid Session');
        }
      });
  }

  refresh(): void {
    this.getCurrentUser().getSession((err, session) => { });
  }

  private getSession(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          throw new Error(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  private getAttributes(user: CognitoUser): Promise<Array<CognitoUserAttribute>> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attrs: Array<CognitoUserAttribute>) => {
        if (err) {
          reject(err);
        } else {
          resolve(attrs);
        }
      });
    });
  }

  private formatAttrs(data: any): Array<CognitoUserAttribute> {
    const retVal: Array<CognitoUserAttribute> = [];
    for (const prop in data) {
      if (data.hasOwnProperty(prop)) {
        retVal.push(new CognitoUserAttribute({
          Name: prop,
          Value: data[prop]
        }));
      }
    }
    return retVal;
  }

  getUserAttributes(): Promise<Array<CognitoUserAttribute>> {
    const user: CognitoUser = this.getCurrentUser();
    return this.getSession(user)
      .then(() => {
        return this.getAttributes(user);
      });
  }

  async refreshSession() {
    const user = await this.getCurrentUser();
    const userSession = await this.getCurrentUserSession();
    return new Promise((resolve, reject) => {
      user.refreshSession(userSession.getRefreshToken(), (err, response) => {
        if (err) {
          reject(err);
        }
        resolve(response);
      });
    });
  }
  globalSignOut(username: string) {
    const userData = {
      Username: username.toLowerCase(),
      Pool: this.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.getSession((err: Error, session: CognitoUserSession) => {
      if (err) {
        console.log(err);
      } else {
        cognitoUser.globalSignOut({ onFailure: e => console.log(e), onSuccess: r => console.log('Logout success' + r) });
      }
    });
  }

}
