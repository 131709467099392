import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppToasterService {

  constructor(
    private toasterSerice: ToastrService
  ) { }

  showSuccess(message: string) {
    this.toasterSerice.success(message);
  }

  showError(message: string) {
    this.toasterSerice.error(message);
  }
}
