import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { QueryParams } from '../../common/interfaces';

@Injectable()
export class ParamsService {

  constructor() { }

  getQueryParams(params?: QueryParams): string {
    let args: HttpParams = new HttpParams();
    Object.keys(params).forEach((paramName: string) => {
      args = args.append(paramName, String(params[paramName]));
    });
    return args.toString();
  }
}
