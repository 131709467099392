import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationComponent } from './confirmation/confirmation.component';
import { VideoCallNotificationComponent } from './video-call-notification/video-call-notification.component';

@Injectable()
export class ModalService {

  constructor(
    private modalService: NgbModal
  ) { }

  openConfirmationModal(title: string, body: string, messageToaster?: boolean): Promise<any> {
    const modalRef = this.modalService.open(ConfirmationComponent,
      { backdrop: 'static', centered: true, size: 'sm' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.message = messageToaster;
    return modalRef.result;
  }

  openVideoCallNotificationModal(title: string, body: string, imgUrl: string): Promise<any> {
    const modalRef = this.modalService.open(VideoCallNotificationComponent,
      { backdrop: 'static', centered: true, size: 'sm' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.body = body;
    modalRef.componentInstance.imgUrl = imgUrl;
    return modalRef.result;
  }

  open(content: any, isStatic: boolean = true, options?: NgbModalOptions) {
    if (isStatic) {
      if (!options) {
        options = {
          backdrop: 'static'
        };
      } else {
        options.backdrop = 'static';
      }
    }
    return this.modalService.open(content, options);
  }
}
