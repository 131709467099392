import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AppConfigConstants, Messages, ExceptionMessages, TeamComponentMesages } from '../common/constants';
import { flatMap, refCount, publishReplay, first, tap } from 'rxjs/operators';

// Services
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { CognitoService } from './services/cognito.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { AppToasterService } from './services/app-toaster.service';
import {environment} from '../../environments/environment';
@Injectable()

export class AuthHeaderInterceptor implements HttpInterceptor {
    private refreshTokenObservable: Observable<any>;
    private baseUrl = 'api/v1';
    constructor(
        private storageService: StorageService,
        private authService: AuthService,
        private congintoService: CognitoService,
        private appToasterService: AppToasterService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clientParams = request.params.has('integration');
        let path = this.baseUrl;

        if (request.url.indexOf('download') > -1) {
            path = request.url;
        } else {
            if (!request.params.get('integration') && request.url.indexOf('chat') < 0) {
                path += `${!clientParams ? '/integration' : ''}`;
            }

            if (request.url.indexOf('?') !== -1) {
                path += `/${request.url}&_=${new Date().getTime()}`;
            } else {
                path += `/${request.url}?_=${new Date().getTime()}`;
            }
        }
        return from(this.getToken())
            .pipe(
                flatMap(token => {
                    if (request.url.indexOf('download') > -1) {
                        token = null;
                    }
                    request = request.clone({
                        setHeaders: {
                            'Authorization': token || '',
                        },
                        url: path,
                        params: request.params.delete('integration'),
                    });

                    return next.handle(request).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                        // do error handling here
                        if (err instanceof HttpErrorResponse) {
                            if (err.error && err.error.meta) {
                                switch (err.error.meta.error) {
                                    case AppConfigConstants.cognitoErrorResponses.notAuthorizedException:
                                        // this.appToasterService.showError(ExceptionMessages.notAuthorizedException);
                                        break;
                                    case AppConfigConstants.cognitoErrorResponses.limitExceededException:
                                        this.appToasterService.showError(ExceptionMessages.limitExceededException);
                                        break;
                                    case AppConfigConstants.cognitoErrorResponses.invalidPasswordException:
                                        this.appToasterService.showError(ExceptionMessages.invalidPasswordException);
                                        break;
                                    case AppConfigConstants.cognitoErrorResponses.invalidParameterException:
                                        this.appToasterService.showError(ExceptionMessages.invalidParameterException);
                                        break;
                                    case AppConfigConstants.backendErrorResponses.error:
                                        switch (err.error.meta.message) {
                                            case AppConfigConstants.backendErrorResponses.accountExists:
                                                this.appToasterService.showError(AppConfigConstants.backendErrorResponses.accountExists);
                                                break;
                                            case AppConfigConstants.backendErrorResponses.unauthorized:
                                                this.authService.logout();
                                                break;
                                            default:
                                                break;
                                        }
                                        break;

                                    case TeamComponentMesages.existTeamMessage:
                                        this.appToasterService.showError(TeamComponentMesages.existTeamMessage);
                                        break;
                                    default:
                                        break;
                                }

                            } else {
                                this.appToasterService.showError(Messages.serverError);
                            }
                        }
                    }));
                })
            );
    }

    getToken() {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const userSession = await this.authService.getCurrentUserSession();
                const token_expiry = userSession.getAccessToken().getExpiration() * 1000;
                if (token_expiry && token_expiry - new Date().getTime() < 120) {
                    this.refreshToken()
                        .subscribe((response: CognitoUserSession) => {
                            resolve(response.getAccessToken().getJwtToken());
                        }, (error) => {
                            let jsonError = error.json();
                            jsonError = jsonError.errors[0];
                            this.storageService.clear();
                        });
                } else {
                    resolve(userSession.getAccessToken().getJwtToken());
                }
            } catch (err) {
                resolve();
            }
        });
    }

    /**
  * use to refresh the existing token.
  * @param options
  * @returns {Observable<T>}
  */
    private refreshToken(): Observable<any> {
        if (!this.refreshTokenObservable) {
            this.refreshTokenObservable = from(this.congintoService.refreshSession())
                .pipe(
                    publishReplay(1),
                    refCount()
                );
            setTimeout(() => {
                this.refreshTokenObservable = null;
            }, 10000);
        }

        return this.refreshTokenObservable.pipe(first());
    }
}
