import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer, from, BehaviorSubject } from 'rxjs';
import { mergeMap, filter, take, map } from 'rxjs/operators';
import { AppToasterService } from './app-toaster.service';
import { AnalyticsComponent } from '../../analytics/analytics.component';

// Services
import { CognitoService } from './cognito.service';

@Injectable()
export class PollingService {

  startPolling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  pollObservable: Observable<any>;
  constructor(
    private cognitoService: CognitoService,
    private toasterService: AppToasterService,
    private analyticsComponent: AnalyticsComponent,
    private http: HttpClient,
  ) {
  }


  initiateChatPolling(reassign: boolean = false) {
    if (this.pollObservable && !reassign) {
      return this.pollObservable;
    }
    this.pollObservable = timer(0, 1000 * 10)
      .pipe(
        mergeMap(() => from(this.cognitoService.isAuthenticated())),
        mergeMap(() => this.getData()),
        filter((response: any) => {
          if (Object.keys(response).length === 1) {
            if (Object.keys(response).indexOf('isFirebaseUpdating') > -1) {
              if (this.analyticsComponent.getSyncProgress() && response.isFirebaseUpdating === false) {
                this.toasterService.showSuccess('Sync Completed...');
                this.analyticsComponent.updateSyncProgress(false);
                this.analyticsComponent.updateFirebase('', false);
                location.reload(true);
              } else if (this.analyticsComponent.getSyncProgress() === false && response.isFirebaseUpdating === true) {
                this.analyticsComponent.updateSyncProgress(true);
              }
              return false;
            }
          }
          return Object.keys(response).length > 0;
        }),
        take(1)
      );
    return this.pollObservable;
  }

  getPollingStatus() {
    const pollObserver = timer(0, 1000 * 10)
      .pipe(
        mergeMap(() => from(this.cognitoService.isAuthenticated())),
        mergeMap(() => this.getData()),
        filter((response: any) => {
          if (Object.keys(response).length === 1) {
            if (Object.keys(response).indexOf('isFirebaseUpdating') > -1) {
              return false;
            }
          }
          return Object.keys(response).length === 0;
        }),
        take(1)
      );
    return pollObserver;
  }

  getData() {
    const params: any = { 'integration': 'true' };
    if (window.location && window.location.hash === '#/analytics') {
      params['checkFirebaseAnalytics'] = true;
    }
    return this.http.get('chat/poll', { params: params })
      .pipe(
        map((response: any) => response.data)
      );
  }
}
