import { Component, OnInit, OnDestroy, Input, AfterViewInit, Output, EventEmitter, ViewEncapsulation, ElementRef } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

import { Checklist, ChecklistSection } from '../interfaces';
import { ViolationsComponent } from '../../checklist-management/violations/violations.component';
import { ModalService } from '../modal/modal.service';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { forkJoin } from 'rxjs';
import { EditUserModalComponent } from '../../user-management/users/edit-user-modal/edit-user-modal.component';
import { ChecklistManagementService } from '../../checklist-management/checklist-management.service';
import { Violation } from '../../checklist-management/checklist-management.interface';

declare var jQuery: any;

import { AppConfigConstants, SiteMessages, ChecklistMessages } from '../constants'; @Component({
  selector: 'app-checklist-accordian',
  templateUrl: './checklist-accordian.component.html',
  styleUrls: ['./checklist-accordian.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChecklistAccordianComponent implements OnInit, OnDestroy, AfterViewInit {
  ChecklistManagementService: any;
  show: boolean;
  @Input() checklistItems: any;
  @Input() isDraggable: boolean;
  @Input() hasActions: boolean;

  @Output() editSectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteSectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() editChecklistEvent: EventEmitter<any> = new EventEmitter();
  @Output() toggleSectionEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteChecklistEvent: EventEmitter<any> = new EventEmitter();
  @Output() addNewSectionEvent: EventEmitter<any> = new EventEmitter();
  modalRef: any;
  violation: true;

  constructor(
    private dragulaService: DragulaService,
    private modalService: ModalService,
    private spinnerService: SpinnerService,
    private checklistManagementService: ChecklistManagementService
  ) {
    this.show = false;
    dragulaService.createGroup('sortableChecklist', {
      moves: (el, container, handle) => {
        return handle.className === 'icon-drag';
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.dragulaService.destroy('sortableChecklist');
  }

  ngAfterViewInit() {
    this.dragulaService.drag('sortableChecklist')
      .subscribe(args => {
      });
  }

  getData() {

  }

  toggleTooltip(ele: ElementRef) {
    const objEvent = jQuery._data(ele, 'events');
    if (!objEvent || (objEvent && !objEvent.click)) {
      jQuery(ele).tooltip();
      jQuery(ele).on({
        'click': function() {
          jQuery(ele).tooltip();
          jQuery(this).tooltip('enable').tooltip('open');
        },
        'mouseout': function() {
          try {
            jQuery(this).tooltip('destroy');
          } catch {
            console.log('mouseout');
          }
        }
      });
      jQuery(ele).click();
    }
  }

  private onDrag(args) {
    // let [e, el] = args;
    // do something
  }

  expandAllSections(checklist: Checklist, event: any) {
    event.stopPropagation();
    if (checklist['expandAll']) {
      checklist['expandAll'] = false;
      checklist.sections.forEach((section: ChecklistSection) => {
        section['isShow'] = false;
      });
    } else {
      checklist['expandAll'] = true;
      checklist.sections.forEach((section: ChecklistSection) => {
        section['isShow'] = true;
      });
    }
  }

  editChecklistItem(checklist: Checklist, index: number) {
    this.editChecklistEvent.emit({ checklist, index });
  }

  deleteChecklistItem(checklistIndex: number) {
    this.modalService.openConfirmationModal(ChecklistMessages.deleteChecklistTitle, ChecklistMessages.deleteChecklistBody)
      .then(() => {
        this.deleteChecklistEvent.emit({ checklistIndex });
      }, error => {
      });
  }

  addNewSectionItem(checklistIndex: number, checklist: Checklist) {
    this.addNewSectionEvent.emit({ checklistIndex, checklist });
  }

  editSectionItem(section: ChecklistSection, checklistIndex: number, sectionIndex: number, checklist: Checklist) {
    this.editSectionEvent.emit({ section, checklistIndex, sectionIndex, checklist });
  }
  toggleSectionItem(section: ChecklistSection, isActive: boolean, sectionIndex: number, checklist: Checklist) {
    this.toggleSectionEvent.emit({ section, isActive, sectionIndex, checklist });
  }
  deleteSectionItem(checklistIndex: number, sectionIndex: number) {
    this.modalService.openConfirmationModal(ChecklistMessages.deleteSectionTitle, ChecklistMessages.deleteSectionBody)
      .then(() => {
        this.deleteSectionEvent.emit({ checklistIndex, sectionIndex });
      }, error => {
      });
  }

  openEditViolationModal = (violationToEdit: Violation) => {
    this.modalRef = this.modalService.open(ViolationsComponent, true, { size: 'lg' });
    this.modalRef.componentInstance.violation = violationToEdit;

    this.modalRef.result.then((violation: Violation) => {
      // const message = generateMessage(UserComponentMesages.updateUserMessage, ['{{username}}'], [user.firstName]);
      // this.toasterService.showSuccess(message);
      this.spinnerService.change(false);
    }, (error) => {
      this.spinnerService.change(false);
    });
  }

  openViewViolationModal(violation: Violation) {
    this.show = true;
  }
}


