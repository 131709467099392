import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SpinnerService } from 'src/app/core/spinner/spinner.service';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() attachment: any;
  @Input() followUp: any;
  @Output() removed = new EventEmitter<any>();
  ext = 'pdf.png';
  exts: object = {
    'pdf': 'fa fa-file-pdf-o',
    'mov': 'fa fa-file-video-o',
    'jpeg': 'fa fa-file-image-o',
    'jpg': 'fa fa-file-image-o',
    'png': 'fa fa-file-image-o',
    'mp4': 'fa fa-file-video-o',
    'webm': 'fa fa-file-video-o',
    'ogg': 'fa fa-file-audio-o',
    'mp3': 'fa fa-file-audio-o',
    'm4a': 'fa fa-file-audio-o'
  };

  constructor(    private spinnerService: SpinnerService,
    private dataService: DataService,

    ) { }

  ngOnInit() {
    this.findEXT();
  }

  findEXT() {
    if (this.attachment) {
      console.log(this.attachment, 'attach');
      this.ext = this.exts[this.attachment.extension];
    }
  }

  remove() {
    console.log(this.followUp.attachments, 'follow n attach');
    const index = this.followUp.attachments.findIndex(item => item.id === this.attachment.id);
    this.followUp.attachments.splice(index, 1);
    this.removed.emit(this.followUp);
  }

  downloadSite(document: any) {
    if (document.metaData.path) {
      this.spinnerService.change(true);
      const mediaId = document.metaData.path;
      this.dataService.downloadMedia(mediaId).subscribe(res => {
        this.spinnerService.change(false);
        this.dataService.downloadFile(res, document.name, res.type.split('/').pop());
      }, err => {
        this.spinnerService.change(false);
      });
    }

  }
}
