import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { AppCommonModule } from './common/common.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AgmCoreModule } from '@agm/core';

// Components
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { APP_INITIALIZER } from '@angular/core';
import { StorageService } from './core/services/storage.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ForbiddenComponent } from './forbidden/forbidden.component';
// import { NgbdDatepickerPopup } from './datepicker-popup';

export function init_app(storage: StorageService, configService: NgxPermissionsService) {
  return () => {
    if (storage.getItem('policies')) {
      configService.loadPermissions(storage.getObject('policies'));
    }
  };

}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    // DashboardmyTeamComponent,
    // DashboardmyDataComponent,
  ],
  imports: [
    // NgbdDatepickerPopup,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAHJfi7jq7Tcc7v4wDdz5Zj8i9cS75FGhg'
    }),
    // AvatarModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    NgbModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    CoreModule,
    AppCommonModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    {
      useClass: HashLocationStrategy, provide: LocationStrategy,
    },
    StorageService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [StorageService, NgxPermissionsService],
      multi: true
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
