import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver/FileSaver';


// Interfaces
import { InspectionType, State } from '../../common/interfaces';
import { Organization } from '../../site-management/site-management-interfaces';
import { ParamsService } from './params.service';

@Injectable()
export class DataService {


  constructor(

    private http: HttpClient,
    private paramsService: ParamsService
  ) {
  }

  getServiceableRegion(): Observable<any> {
    return this.http.get(`serviceable-region`)
      .pipe(
        map((response: any) => {
          return <Array<any>>response.data.serviceableRegions;
        })
      );
  }

  getInspectionTypes(): Observable<Array<InspectionType>> {
    return this.http.get(`inspection-type`, { params: { 'pageSize': '-1' } })
      .pipe(
        map((response: any) => {
          return <Array<InspectionType>>response.data.inspectionTypes;
        })
      );
  }


  getInspectionItemTypes(): Observable<Array<InspectionType>> {
    return this.http.get(`actionable-items-def`)
      .pipe(
        map((response: any) => {
          return <Array<InspectionType>>response.data.actionableItemDefs;
        })
      );
  }

  getOrganizations(): Observable<Array<Organization>> {
    return this.http.get(`organization`, { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => {
          return <Array<Organization>>response.data.organizations;
        })
      );
  }

  changeRoomStatus(roomId, status) {
    return this.http.put(`chat/status`, { roomId, status }, { params: { 'integration': 'true' } });
  }

  getStates(): Observable<Array<State>> {
    return this.http.get(`state`, { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => {
          return <Array<State>>response.data.states;
        })
      );
  }

  // 5+ files holds this function. Do not modify this function until it requires.
  downloadGlobalMedia(mediaId) {
    return this.http.get(`media?mediaId=${mediaId}`, { responseType: 'blob', params: { 'integration': 'true' } });
  }

  // 5+ files holds this function. Do not modify this function until it requires.
  downloadGlobalFile(data: any, filename: string, fileType: string) {
    const blob = new Blob([data], { type: 'application/' + fileType });
    saveAs(blob, filename + fileType);
  }


  downloadMedia(url) {
    return this.http.get(`${url}?download=file`, { responseType: 'blob', params: { 'client': 'true' } });
  }

  downloadFile(data: Blob, filename: string, fileType: string) {
    const blob = new Blob([data], { type: 'application/' + fileType });
    saveAs(blob, filename);
  }


  getEvents(paramOptions) {
    let params = new HttpParams();
    const _types = JSON.stringify(paramOptions);
    params = params.set('inspectionTypesDefIds', _types);
    return this.http.get(`action-def`, { params })
      .pipe(
        map((response: any) => {
          return <Array<State>>response.data.actionDefs;
        })
      );
  }

  hideOtherListItems(items: any) {
    items.map(item => item['show'] = false);
  }

  sortByProperty(array, property): Array<any> {
    return array.sort((x, y) => {
      return (x[property] === y[property]) ? 0 : x[property] ? 1 : -1;
    });
  }
}
