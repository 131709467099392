import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { QueryParams } from '../interfaces';

@Component({
  selector: 'app-sorting-header',
  templateUrl: './sorting-header.component.html',
  styleUrls: ['./sorting-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SortingHeaderComponent implements OnInit {
  @Input() items: Array<string>;
  @Input() params: QueryParams;

  @Output() sort: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sortChange(item) {
    if (item.sortBy) {
      this.items = this.items.map((column: any) => {
        if (column.hasOwnProperty('order') && item.sortBy !== column.sortBy) {
          delete column.order;
        }
        return column;
      });
      item.order = item.order === 'asc' ? 'desc' : 'asc';
      this.params.sortBy = item.sortBy;
      this.params.sortOrder = item.order;
      this.sort.emit(item);
    }
  }

}
