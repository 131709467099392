import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  @Input() title;
  @Input() body;
  @Input() message;

  ngOnInit() {
  }

  dismissModal() {
    this.activeModal.dismiss('DISMISSED !');
  }

  closeModal() {
    this.activeModal.close();
  }

}
