import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { CognitoService } from '../../core/services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private cognitoService: CognitoService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.cognitoService.isAuthenticated()
      .then((isAuthenticated: boolean) => {
        if ((!next.url.length || !next.url[0].path.length) && state.url.indexOf('dashboard') === -1) {
          this.router.navigate(['/dashboard']);
        }
        return isAuthenticated;
      }, (error) => {
        if ((next.url.length && next.url[0].path.length) || state.url.indexOf('dashboard') !== -1) {
          this.router.navigate(['']);
          return error;
        }
        return true;
      });
  }
}
