import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/core/services/validation.service';
import { HttpClient } from '@angular/common/http';
import { AppToasterService } from 'src/app/core/services/app-toaster.service';
import { AuthService } from '../../../core/services/auth.service';
import { ChangePasswordService } from 'src/app/core/services/change-password.service';
import { AppConfigConstants } from '../../constants';
import { SpinnerService } from 'src/app/core/spinner/spinner.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  PreviousPassword: string;
  ProposedPassword: string;
  confirmNewPassword: string;
  showPassword = false;
  shownewPassword = false;
  showconfirmPassword = false;
  apiErrorMsg = '';
  constructor(private activeModal: NgbActiveModal,
    private builder: FormBuilder,
    private validationService: ValidationService,
    private changPassword: ChangePasswordService,
    private toastrService: AppToasterService,
    private authService: AuthService,
    private spinnerService: SpinnerService

  ) { }

  ngOnInit() {
    this.initChangePasswordForm();
  }

  initChangePasswordForm() {
    this.changePasswordFormGroup = this.builder.group({
      'oldPassword': new FormControl('', [Validators.required, this.validationService.hasWhiteSpace,
      this.validationService.passwordValidator,
      this.validationService.confirmPasswordValidation('oldPassword', true)
      ]),
      'newPassword': new FormControl('', [Validators.required, this.validationService.newPasswordValidation,
      this.validationService.noWhiteSpace, this.validationService.validPassword, Validators.minLength(8),
      this.validationService.confirmPasswordValidation('newPassword', false)
      ]),
      'confirmPassword': new FormControl('', [Validators.required, this.validationService.confirmPswdValdiation,
      this.validationService.noWhiteSpace,
      this.validationService.confirmPasswordValidation('confirmPassword', true)
      ])
    });
  }

  changePassword(data) {
    if (this.validationService.validateForm(this.changePasswordFormGroup)) {
      const PreviousPassword = data.value.oldPassword;
      const ProposedPassword = data.value.newPassword;
      const confirmNewPassword = data.value.confirmPassword;
      if (PreviousPassword !== ProposedPassword) {
        if (ProposedPassword === confirmNewPassword) {
          this.spinnerService.change(true);
          this.changPassword.changePassword({ PreviousPassword, ProposedPassword })
            .subscribe(response => {
              this.spinnerService.change(false);
              console.log('success', response);
              this.toastrService.showSuccess('Password has been changed Successfully');
              this.activeModal.dismiss('DISMISSED !');
              this.authService.logout();
            }, err => {
              this.spinnerService.change(false);
            });
        } else {
          this.toastrService.showError('Passwords do not match');
        }
      } else {
        this.toastrService.showError('old and new passwords must be different');
      }

    }
  }

  dismissModal() {
    this.activeModal.dismiss('DISMISSED !');
  }
}
