import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { errors } from '../errors';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  file: File;
  @ViewChild('file') fileInput: ElementRef;
  errors = errors;
  files: Array<any>;
  filesCount: number;
  @Input() uploadId: string;
  @Input() maxSize: number;
  @Input() types;
  @Input() allowMultiple: boolean;

  @Output() fileUpload: EventEmitter<any> = new EventEmitter();
  @Output() fileError: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.types = this.types.join(',');
    if (this.allowMultiple) {
      this.fileInput.nativeElement.setAttribute('multiple', '');
    }
  }

  onFileChange($event) {
    this.files = Array.from($event.target.files);
    $event.target.value = null;
    this.filesCount = this.files.length;
    this.files.forEach((file: File, index: number) => {
      this.file = file; // <--- File Object for future use.
      if (this.file && this.file.type.length && (this.types.indexOf(this.file.type) >= 0 || !this.types.length)) {
        if (!this.maxSize || this.file.size <= this.maxSize * 1000000) { // x mb = x000000 bytes
          const reader = new FileReader();
          reader.onload = this.handleReaderLoaded.bind(this, index);
          reader.readAsDataURL(this.file);
        } else {
          this.fileError.emit(this.errors.InvalidFileSize);
          this.fileInput.nativeElement.value = '';
        }
      } else if (this.file) {
        this.fileError.emit(this.errors.InvalidFileType);
        this.fileInput.nativeElement.value = '';
      }
    });
  }

  handleReaderLoaded(index, readerEvt) {
    const base64String = readerEvt.target.result;
    this.fileUpload.emit({
      base64Url: base64String,
      fileObj: this.files[index],
      totalFiles: this.filesCount
    });
  }

}
