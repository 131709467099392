import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { QueryParams } from '../interfaces';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchBoxComponent implements OnInit {
  @Input() params: QueryParams;
  @Input() placeholder: string;
  @Input() disable: boolean;
  @Output() search: EventEmitter<string> = new EventEmitter();
  debouncer: Subject<string> = new Subject();
  searchText: string;

  constructor() {
    this.debouncer.pipe(debounceTime(350))
      .subscribe((val) => this.search.emit(val));
  }

  ngOnInit() {
  }

  searchItem() {
    this.debouncer.next(this.searchText);
  }

  clearSearch() {
    this.searchText = '';
    if (this.params.search) {
      this.search.emit(this.searchText);
    }
  }

}
