import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { SortingHeaderComponent } from './sorting-header/sorting-header.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ChecklistAccordianComponent } from './checklist-accordian/checklist-accordian.component';
import { SearchBoxComponent } from './search-box/search-box.component';

// Pipes
import { PipeModule } from './pipes/pipe.module';
import { ViolationsComponent } from '../checklist-management/violations/violations.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { ChangePasswordComponent } from './modal/change-password/change-password.component';


@NgModule({
  imports: [
    CommonModule,
    DragulaModule.forRoot(),
    NgbModule,
    PipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    ControlMessagesComponent,
    SortingHeaderComponent,
    FileUploaderComponent,
    ChecklistAccordianComponent,
    SearchBoxComponent,
    ViolationsComponent,
    FilePreviewComponent,
    ChangePasswordComponent
  ],
  exports: [
    ControlMessagesComponent,
    SortingHeaderComponent,
    FileUploaderComponent,
    ChecklistAccordianComponent,
    SearchBoxComponent,
    PipeModule,
    NgxPermissionsModule,
    FilePreviewComponent,
    ChangePasswordComponent
  ],
  entryComponents: [
    ViolationsComponent,
    ChangePasswordComponent
  ]

})
export class AppCommonModule { }
