import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Media, MediaPart } from '../../common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  file: any;
  apiConstant = '/api/v1/';

  constructor(private http: HttpClient) { }

  uploadConfig(file) {
    return this.upload(file);
  }

  upload(file) {
    this.file = file;
    return this.http.post('media', {
      contentType: this.file.type,
      contentLength: this.file.size,
    }, { params: { 'integration': 'true' } }).toPromise()
      .then((response: any) => {
        let media: Media;
        media = <Media>response.data;
        media.uploadURL = media.uploadURL.replace(this.apiConstant, '');
        media.completeURL = media.completeURL.replace(this.apiConstant, '');
        media.filename = file.name;
        return this.processFileParts(media);
      })
      .then((etags) => this.completeFileUpload(etags));
  }

  processFileParts(media: Media) {
    const promises = [];
    for (const part of media.parts) {
      promises.push(this.uploadFilePart(media, part));
    }
    return Promise.all(promises);
  }

  uploadFilePart(media: Media, part: MediaPart) {
    return this.readBlockAsDataURL(part.byteRangeStart, part.byteRangeEnd)
      .then((data) => {
        return this.http.put(`${media.uploadURL}`, {
          contentType: this.file.type,
          contentLength: this.file.size,
          uploadId: media.uploadId,
          partIndex: part.index,
          data
        }, { params: { 'integration': 'true' } }).toPromise();
      })
      .then((response: any) => {
        return {
          index: part.index,
          ETag: response.data.ETag,
          media,
        };
      });
  }

  completeFileUpload(ETags) {
    return this.http.post(`${ETags[0].media.completeURL}`, {
      uploadId: ETags[0].media.uploadId,
      parts: ETags
    }, { params: { 'integration': 'true' } }).toPromise()
      .then((response: any) => {
        return ETags[0].media;
      });
  }

  readBlockAsDataURL(start, end) {
    const fileReader = new FileReader();
    const blob = this.file.slice(start, end);
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (event: any) => {
        const target = (event.target);
        if (target.error == null) {
          const result = target.result;
          resolve(result.replace('data:;base64,', ''));
        } else {
          reject(target.error);
        }
      };
      fileReader.readAsDataURL(blob);
    });
  }
}
