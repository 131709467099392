import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Services
import { AppToasterService } from './services/app-toaster.service';
import { DataService } from './services/data.service';
import { ParamsService } from './services/params.service';
import { PollingService } from './services/polling.service';
import { UploadFilesService } from './services/upload-files.service';
import { ValidationService } from './services/validation.service';
import { CognitoService } from './services/cognito.service';
import { StorageService } from './services/storage.service';
import { SpinnerService } from './spinner/spinner.service';
import { AuthService } from './services/auth.service';
import { AnalyticsComponent } from '../analytics/analytics.component';

// Interceptor
import { AuthHeaderInterceptor } from './interceptor';

// Components
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    SpinnerComponent,
  ],
  exports: [
    SpinnerComponent,
  ],
  providers: [
    AppToasterService,
    DataService,
    ParamsService,
    PollingService,
    UploadFilesService,
    ValidationService,
    StorageService,
    CognitoService,
    SpinnerService,
    AuthService,
    AnalyticsComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
  ]
})
export class CoreModule { }
