import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Polling Service
import { PollingService } from '../../../core/services/polling.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-video-call-notification',
  templateUrl: './video-call-notification.component.html',
  styleUrls: ['./video-call-notification.component.scss']
})
export class VideoCallNotificationComponent implements OnInit {

  pollSubscriber: Subscription;
  constructor(
    private activeModal: NgbActiveModal,
    private pollingService: PollingService
  ) { }

  @Input() title;
  @Input() body;
  @Input() imgUrl;

  ngOnInit() {
    this.pollSubscriber = this.pollingService.getPollingStatus()
      .subscribe(() => {
        this.closeModal();
      }, error => {
        console.log('error', error);
      });
  }

  dismissModal() {
    this.pollSubscriber.unsubscribe();
    this.activeModal.dismiss('DISMISSED !');
  }

  closeModal(ok: boolean = false) {
    this.pollSubscriber.unsubscribe();
    this.activeModal.close(ok);
  }

}
