import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Interfaces
import { InspectionType } from '../common/interfaces';
import {
  Penalty, Violation, Checklist, Section, CorrectiveAction, ChecklistSetupData,
  ChecklistSetupDataItemTypes, ChecklistSetupDataOptionTypes, ActionDef, ChecklistItem,
  Option, ChecklistItemOptionTypesDef, ChecklistItemType
} from './checklist-management.interface';
import { ParamsService } from '../core/services/params.service';


@Injectable({
  providedIn: 'root'
})
export class ChecklistManagementService {
  checklistSetupData: ChecklistSetupData;
  itemTypes: ChecklistSetupDataItemTypes[];
  optionTypes: ChecklistSetupDataOptionTypes[];

  constructor(
    private http: HttpClient,
    private paramsService: ParamsService
  ) { }

  getInspectionTypes(): Observable<Array<InspectionType>> {
    return this.http.get('inspection-type', { params: { 'pageSize': '-1' } })
      .pipe(map((response: any) => {
        return <Array<InspectionType>>response.data.inspectionTypes;
      }));
  }

  getInspectionType(id: string): Observable<InspectionType> {
    return this.http.get(`inspection-type/${id}`)
      .pipe(map((response: any) => {
        return <InspectionType>response.data.inspectionTypes;
      }));
  }

  getChecklistSetupData(): Observable<ChecklistSetupData> {
    return this.http.get('checklist/setup-data')
      .pipe(map((response: any) => {
        return <ChecklistSetupData>{
          itemTypes: <ChecklistSetupDataItemTypes>response.data.itemTypes,
          optionTypes: <ChecklistSetupDataOptionTypes>response.data.optionTypes,
        };
      }));
  }

  getChecklistItemTypes(): Observable<Array<ChecklistSetupDataItemTypes>> {
    return this.http.get(`checklist-item-type`)
      .pipe(map((response: any) => {
        return <Array<ChecklistSetupDataItemTypes>>response.data.checklistItemTypes;
      })
      );
  }
  getChecklistOptionTypes(): Observable<Array<ChecklistSetupDataOptionTypes>> {
    return this.http.get(`checklist-item-option-type-def`)
      .pipe(map((response: any) => {
        return <Array<ChecklistSetupDataOptionTypes>>response.data.checklistItemOptionTypeDefs;
      })
      );
  }
  getChecklist(inspectionTypeId: string, search: string): Observable<Array<Checklist>> {
    return this.http.get(`checklist?inspectionTypeId=${inspectionTypeId}&search=${search}`)
      .pipe(
        map((response: any) => {
          return <Array<Checklist>>response.data.checklists;
        })
      );
  }
  getChecklistbyId(checklistId: string): Observable<Array<Checklist>> {
    return this.http.get(`checklist/${checklistId}`)
      .pipe(
        map((response: any) => {
          return <Array<Checklist>>response.data.checklists;
        })
      );
  }
  addNewChecklist(checklist): Observable<any> {
    return this.http.post(`checklist`, checklist)
      .pipe(
        map((response: any) => {
          return <any>response.data.checklists;
        })
      );
  }
  getNewChecklist() {
    return <Checklist>{
      id: null,
      externalId: null,
      actionsDefId: null,
      inspectionTypeId: null,
      description: null,
      name: null,
      isActive: true,
      clientSpecific: null,
      sections: []
    };
  }

  getNewChecklistSection() {
    return <Section>{
      id: null,
      externalId: null,
      description: null,
      shortDescription: null,
      isDefault: true,
      isActive: true,
      checklistItemDefIds: [],
      checklistItems: []
    };
  }

  getNewChecklistItem(typeId: string) {
    return <ChecklistItem>{
      description: null,
      shortDescription: null,
      checklistItemTypeId: typeId,
      options: [],
      violation: null,
      checklistItemType: this.itemTypes.find(x => x.id === typeId)
    };
  }
  getNewChecklistItemOption() {
    return <Option>{
      id: null,
      description: null,
      shortDescription: null,
      isDefault: false,
      checklistItemOptionTypesDefId: null,
    };
  }
  getchecklistItemOptionTypesDef() {
    return <ChecklistItemOptionTypesDef>{
      id: '',
      type: null,
      description: null
    };
  }

  getPenalities(paramOptions?): Observable<Array<Penalty>> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`penalty?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return <Array<Penalty>>response.data.users;
        })
      );
  }

  getViolations(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`violation-def?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return response;
          // return <Array<Violation>>response.data.violationDefs;
        })
      );
  }

  addViolation(violation: Violation): Observable<Violation> {
    return this.http.post(`violation-def`, [violation])
      .pipe(
        map((response: any) => {
          return <any>response.data.violationDefs;
        })
      );
  }

  addNewCorrectiveAction(correctiveActions: CorrectiveAction): Observable<CorrectiveAction> {
    return this.http.post(`violation-def`, [correctiveActions])
      .pipe(
        map((response: any) => {
          return <any>response.data.violationDefs.correctiveActions;
        })
      );
  }

  addChecklistSection(section: Section): Observable<any> {
    return this.http.post(`checklist-section`, [section])
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  addChecklistItem(checklistItem: ChecklistItem): Observable<any> {
    return this.http.post(`checklist-item`, [checklistItem])
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  hideOtherListItems(items: any) {
    items.map(item => item['show'] = false);
  }

  getActions(paramOptions?): Observable<Array<ActionDef>> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`action-def?${params.replace(/\+/g, '%2B')}`)
      .pipe(
        map((response: any) => {
          return <Array<ActionDef>>response.data.actionDefs;
        })
      );
  }
}
