export const environment = {
    production: false,
    region: 'us-east-2',
    userPoolId: 'us-east-2_RIDRKEOjR',
    clientId: '31cdt36obolh1uhj18fk8c49li',
    identityPoolId: '',
    version: '9.1.0-1',
    baseURL: 'https://qa.njdep.arinspect.com/api/v1',
};

