import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Constants
import { AppConfigConstants } from '../../common/constants';

// Services
import { CognitoService } from '../../core/services/cognito.service';
import { ValidationService } from '../../core/services/validation.service';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { VERSION } from 'src/environments/version';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordFormGroup: FormGroup;
  username: string;
  code: string;
  apiErrorMsg = '';
  version = VERSION.version;

  constructor(
    private builder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private spinnerService: SpinnerService,
    private cognitoService: CognitoService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.resetPasswordFormGroup = this.builder.group({
      'newPassword': new FormControl('', [
        Validators.required,
        this.validationService.noWhiteSpace, Validators.minLength(8),
        this.validationService.validPassword,
        this.validationService.passwordValidator,
        this.validationService.confirmPasswordValidation('confirmPassword', true)
      ]),
      'confirmPassword': new FormControl('', [
        Validators.required,
        this.validationService.confirmPasswordValidation('newPassword', false)
      ]),
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params.code ? params.code : null;
      this.username = params.username ? params.username : null;
    });
  }

  async onSubmit() {
    if (this.validationService.validateForm(this.resetPasswordFormGroup)) {
      this.spinnerService.change(true);
      const resetForm = this.resetPasswordFormGroup.getRawValue();
      this.cognitoService.resetPassword(this.username, this.code, resetForm.newPassword)
        .then(this.success, this.resetError);
    }
  }

  success = (response: any) => {
    this.spinnerService.change(false);
    // this.cognitoService.globalSignOut(this.username);
    this.router.navigate(['./']);
  }

  resetError = (err: Error) => {
    this.spinnerService.change(false);
    if (err.name === AppConfigConstants.cognitoErrorResponses.expiredCodeException) {
      this.apiErrorMsg = 'The link is invalid or expired.';
    } else {
      this.apiErrorMsg = 'The link is invalid or expired.';
    }
  }

}
