import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';
import { CognitoService } from './cognito.service';
import { AppConfigConstants } from '../../common/constants';
import { StorageService } from './storage.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {

  clientId: string;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private congitoService: CognitoService,
    private permissionsService: NgxPermissionsService,
  ) {
    const clientData = this.storageService.getObject(AppConfigConstants.clientData);
    this.clientId = clientData ? clientData['clientId'] : undefined;
  }


  getClientData() {
    return this.http.get('user/me', { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => {
          return response.data.user;
        })
      );
  }

  getClientMetaData() {
    return this.http.get('clientMetadata')
      .pipe(
        map((response: any) => {
          return response.data.clientMetadata[0];
        })
      );
  }

  saveToken(data: CognitoUserSession) {
    localStorage.setItem('accessToken', data.getAccessToken().getJwtToken());
    localStorage.setItem('refreshToken', data.getRefreshToken().getToken());
  }

  logout() {
    // remove user from local storage to log user out
    this.permissionsService.flushPermissions();
    this.storageService.clear();
    this.router.navigate(['/']);
  }

  getCurrentUserSession(): Promise<CognitoUserSession> {
    return this.congitoService.getCurrentUserSession();
  }

  forgotPassword(workEmail: string): Observable<any> {
    const payload = {
      'workEmail': workEmail
    };
    return this.http.post('user/passwordResent', payload)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
