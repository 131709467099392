import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParamsService } from '../core/services/params.service';
import { InspectionType } from '../common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private http: HttpClient,
    private paramsService: ParamsService
  ) { }

  getAnalyticsData(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.post(`analytics?${params.replace(/\+/g, '%2B')}`, paramOptions)
      .pipe(
        map((response: any) => {
          return <any>response;
        })
      );
  }
  getLocationData(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`analytics/location?${params.replace(/\+/g, '%2B')}`, paramOptions)
      .pipe(
        map((response: any) => {
          return <any>response.data.users;
        })
      );
  }
  getFirebaseData(paramOptions?): Observable<any> {
    const params = paramOptions ? this.paramsService.getQueryParams(paramOptions) : 'pageSize=-1';
    return this.http.get(`analytics/firebase?${params.replace(/\+/g, '%2B')}`, paramOptions)
      .pipe(
        map((response: any) => {
          return <any>response.data;
        })
      );
  }
  getInspectionTypes(): Observable<Array<InspectionType>> {
    return this.http.get('inspection-type', { params: { 'pageSize': '-1' } })
      .pipe(map((response: any) => {
        return <Array<InspectionType>>response.data.inspectionTypes;
      }));
  }
  getTeam() {
    return this.http.get(`team`, { params: { 'pageSize': '-1' } })
      .pipe(
        map((response: any) => {
          return response.data.teams;
        })
      );
  }
}
