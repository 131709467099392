import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import * as _ from 'lodash';
import { SpinnerService } from '../core/spinner/spinner.service';
import { StorageService } from '../core/services/storage.service';
import { AppToasterService } from '../core/services/app-toaster.service';
import { DatePipe } from '@angular/common';
import { SortHeader, QueryParams } from '../common/interfaces';
import { Chart } from 'angular-highcharts';
// import { BehaviorSubject } from 'rxjs';
const moment = require('moment');

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  private _teams: any;
  private _chartData: any;
  isSyncInprogress: any = false;
  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean;
    labelKey: string;
  };
  inspections: { name: string; data: number[]; color?: string; }[];
  selectedTeams: { item_id: string, item_text: string }[] = [];
  total_assignment: number;
  total_materials: number;
  pageNumber: any = 1;
  items_per_page: any = 58;
  standard: any[];
  inspectionTypes: any[];
  teams: any[];
  selectedInspectionTypes: any[];
  teamPlaceholder: string;
  users: any[] = [];
  tables: any[] = [];
  // _tables: any = new BehaviorSubject([]);
  actionChartData: any = [];
  overallCompleted: any = 0;
  overallInprogress: any = 0;
  overallInreview: any = 0;
  userLastSeen: String;
  found = true;
  isRefresh = false;
  categories = [];
  lineChart: Chart;
  pieChart: Chart;
  data: any;
  params: any = {};
  userId: string;
  // inspections: any;
  inspectionType: any = '';
  // teams = [];
  // chartData = [];
  userchartData = [];
  scheduleData = [];
  fromDate: any;
  toDate: any;
  prevfromDate: any;
  prevtoDate: any;
  datePickerOptions: any;
  private _selectedDate: any = {};

  constructor(
    private analyticService: AnalyticsService,
    private spinnerService: SpinnerService,
    private storageService: StorageService,
    private toasterService: AppToasterService,
    private datePipe: DatePipe
  ) { }

  onItemSelect(item: any, type: string) {
    if (type === 'inspection type') {
      const inspectionTypeIds = _.map(this.selectedInspectionTypes, 'id') as string[];
      this.params['inspectionTypeId'] = inspectionTypeIds;
    } else {
      const teamIds = _.map(this.selectedTeams, 'id') as string[];
      this.params['teamId'] = teamIds;
    }
    this.getAnalyticsData();
  }
  onSelectAll(item: any, type: string) {
    if (type === 'inspection type') {
      this.selectedInspectionTypes = [];
      this.selectedInspectionTypes.push(...item);
      const inspectionTypeIds = _.map(this.selectedInspectionTypes, 'id') as string[];
      this.params['inspectionTypeId'] = inspectionTypeIds;
    } else {
      this.selectedTeams = [];
      this.selectedTeams.push(...item);
      const teamIds = _.map(this.selectedTeams, 'id') as string[];
      this.params['teamId'] = teamIds;
    }
    this.getAnalyticsData();
  }
  onDeSelectAll(item: any, type: string) {
    if (type === 'inspection type') {
      delete this.params['inspectionTypeId'];
    } else {
      delete this.params['teamId'];
    }
    this.getAnalyticsData();
  }
  onItemDeSelect(item: any, type: string) {
    if (type === 'inspection type') {
      const inspectionTypeIds = _.map(this.selectedInspectionTypes, 'id') as string[];
      this.params['inspectionTypeId'] = inspectionTypeIds;
    } else {
      const teamIds = _.map(this.selectedTeams, 'id') as string[];
      this.params['teamId'] = teamIds;
    }
    this.getAnalyticsData();
  }
  prepare() {
    this.spinnerService.change(true);
    this.analyticService.getInspectionTypes().subscribe(response => {
      if (response && response.length) {
        this.inspectionTypes = response;
      }
      this.getAnalyticsData();
      this.initializeData();
    });
    this.teamPlaceholder = 'Loading...';
    this.analyticService.getTeam().subscribe(response => {
      if (response && response.length) {
        this.teams = response;
      } else {
        this.teamPlaceholder = 'No Teams Available';
      }
    });
  }

  set selectedDate(date) {
    this._selectedDate = date;
    this.fromDate = this._selectedDate.from;
    this.toDate = this._selectedDate.to;
    if (this.fromDate !== this.prevfromDate || this.toDate !== this.prevtoDate) {
      this.prevfromDate = this.fromDate;
      this.prevtoDate = this.toDate;
      this.fromDate = this.formatDate(new Date(this.fromDate));
      this.toDate = this.formatDate(new Date(this.toDate));
    }
    this.spinnerService.change(true);
    let tD: any;
    if (_.isEqual(this.fromDate, this.toDate)) {
      tD = (new Date(this.toDate.year,
        this.toDate.month - 1, this.toDate.day + 1)).toISOString();
    } else {
      tD = (new Date(this.toDate.year,
        this.toDate.month - 1, this.toDate.day)).toISOString();
    }
    const fD = (new Date(this.fromDate.year,
      this.fromDate.month - 1, this.fromDate.day)).toISOString();

    this.params['startDate'] = fD;
    this.params['endDate'] = tD;
    this.getAnalyticsData();
  }

  get selectedDate() {
    return this._selectedDate;
  }

  updateSyncProgress(status: boolean) {
    this.isSyncInprogress = status;
  }

  getSyncProgress() {
    return this.isSyncInprogress;
  }

  ngOnInit() {
    this.initDatePicker();
    this.prepare();
  }

  async updateFirebase(tableName: string, isRefresh?: boolean) {
    this.spinnerService.change(true);
    const params: any = {
      isRefresh: true
    };
    if (isRefresh === false) {
      delete params.isRefresh;
    } else {
      this.isSyncInprogress = true;
    }
    if (tableName) {
      params['tableName'] = tableName;
      params['isSync'] = true;
    }
    await this.analyticService.getFirebaseData(params).subscribe(response => {
      this.spinnerService.change(false);
      if (response && response.tables && response.tables.length) {
        // if (tableName) {
        //   const tables = this.tables;
        //   tables[response.tables[0].name] = response.tables[0];
        //   this._tables.next(tables);
        // } else {
        //   this._tables.next(response.tables);
        // }
        // this._tables.subscribe((data) => {
        //   data = data.sort((a, b) => a.name.localeCompare(b.name));
        //   this.tables = data;
        // });
        if (tableName) {
          const objIndex = _.findIndex(this.tables, {name: response.tables[0].name});
          this.tables[objIndex] = response.tables[0];
        } else {
          this.tables = this.tables.sort((a, b) => a.name.localeCompare(b.name));
          this.tables = response.tables;
        }
      } else if (response && response.success) {
        this.toasterService.showSuccess(response.success);
      }
    }, error => {
      this.spinnerService.change(false);
      if (error.error.meta.message === 'User permission denied') {
        this.toasterService.showError('User permission denied');
      } else {
        if (error.error.meta.message) {
          this.toasterService.showError(error.error.meta.message);
        } else {
          this.toasterService.showError('Something went wrong');
        }
      }
    });
  }

  async getAnalyticsData() {
    this.spinnerService.change(true);
    if (this.params && this.params.inspectionTypeId && this.params.inspectionTypeId.length === 0) {
      delete this.params.inspectionTypeId;
    }
    if (this.params && this.params.teamId && this.params.teamId.length === 0) {
      delete this.params.teamId;
    }
    await this.analyticService.getAnalyticsData(this.params).subscribe(response => {
      if (response && response.data) {
        this.data = response.data;
        this.actionChartData = [];
        this.inspectionTypes.forEach(item => {
          const obj = {
            name: item.name,
            data: [0, 0, 0]
          };
          this.actionChartData.push(obj);
        });
        if (response.data.completed && Object.keys(response.data.completed).length) {
          Object.keys(response.data.completed).forEach(item => {
            const index = this.actionChartData.findIndex(data => data.name === item );
            this.actionChartData[index].data[0] = Number(response.data.completed[item]);
            this.overallCompleted = this.overallCompleted + Number(response.data.completed[item]);
          });
        }
        if (response.data.inprogress && Object.keys(response.data.inprogress).length) {
          Object.keys(response.data.inprogress).forEach(item => {
            const index = this.actionChartData.findIndex(data => data.name === item );
            this.actionChartData[index].data[1] = Number(response.data.inprogress[item]);
            this.overallInprogress = this.overallInprogress + Number(response.data.inprogress[item]);
          });
        }
        if (response.data.inreview && Object.keys(response.data.inreview).length) {
          Object.keys(response.data.inreview).forEach(item => {
            const index = this.actionChartData.findIndex(data => data.name === item );
            this.actionChartData[index].data[2] = Number(response.data.inreview[item]);
            this.overallInreview = this.overallInreview + Number(response.data.inreview[item]);
          });
        }
        this.initialize(response.data);
        this.spinnerService.change(false);
      }
    });
    await this.analyticService.getLocationData(this.params).subscribe(response => {
      if (response && response.length) {
        response.map((timeItem) => {
          if (timeItem.location && timeItem.location.time) {
            return this.userLastSeen = moment(timeItem.location.time).fromNow();
          } else {
            return  this.userLastSeen = 'No data found';
          }
        });
        this.users = response;
      }
    });
    await this.analyticService.getFirebaseData(this.params).subscribe(response => {
      this.isRefresh = true;
      this.params['isRefresh'] = true;
      if (response && response.tables && response.tables.length) {
        // this._tables.next(response.tables);
        // this._tables.subscribe((data) => {
        //   data = data.sort((a, b) => a.name.localeCompare(b.name));
        //   this.tables = data;
        // });
        response.tables = response.tables.sort((a, b) => a.name.localeCompare(b.name));
        this.tables = response.tables;
      } else if (response && response.success) {
        this.toasterService.showSuccess(response.success);
      }
    });
  }

  initialize(data) {
    this.pieChart = new Chart({
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: `${data.actionTotal}` + '<br><p style="font-size:10px">Total Inspection</p>',
        align: 'center',
        verticalAlign: 'middle',
        y: 40
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -40,
            style: {
              fontWeight: 'bold',
              color: 'white',
              fontSize: '18px'
            },
            formatter: function () {
              return this.y;
            }
          },
          showInLegend: true,
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '100%'
        }
      },
      exporting: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: 'Rating',
        innerSize: '50%',
        data: [
          {
            name: 'Inspection Ingested',
            color: '#00b6c9',
            y: data.ingested
          },
          {
            name: 'Inspection Ingestion Pending',
            color: 'rgb(247, 163, 92)',
            y: data.pendingIngestion
          },
        ]
      }]
    });
    this.lineChart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: ['Completed', 'Inprogress', 'Inreview'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:.1f}%</b>'
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },
      credits: {
        enabled: false
      },
      series: this.actionChartData
    });
  }

  initializeData() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: false,
      labelKey: 'sdfsd'
    };
    this.params = {
      pageSize: 58,
      offset: 0,
      sortBy: 'createdAt',
      sortOrder: 'asc'
    };
  }


  initDatePicker() {
    const today = new Date();
    // const prevYr = new Date();
    // prevYr.setDate(today.getDate() - 364);
    this.fromDate = this.formatDate(today);
    this.prevfromDate = this.fromDate;
    const todate = this.formatDate(today);
    this.toDate = todate;
    this.prevtoDate = this.toDate;
    this.datePickerOptions = {
      theme: 'default',
      labels: ['Start', 'End'],
      menu: [
        { alias: 'td', text: 'Today', operation: '0d' },
        { alias: 'tm', text: 'This Month', operation: '0m' },
        { alias: 'lm', text: 'Last Month', operation: '-1m' },
        { alias: 'tw', text: 'This Week', operation: '0w' },
        { alias: 'lw', text: 'Last Week', operation: '-1w' },
        { alias: 'ty', text: 'This Year', operation: '0y' },
        { alias: 'ly', text: 'Last Year', operation: '-1y' },
        { alias: 'ny', text: 'Next Year', operation: '+1y' },
        { alias: 'lyt', text: 'Last year from today', operation: '-1yt' },
      ],
      dateFormat: 'YYYY-MM-DD',
      outputFormat: 'YYYY-MM-DD',
      startOfWeek: 0,
      outputType: 'object',
      locale: 'en',
      date: {
        from: today,
        to: today
      }
    };
  }
  formatDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      day: date.getDate()
    };
    // return year + '-' + monthIndex + '-' + day;
  }


  getData() {
    this.spinnerService.change(true);
    let tD: any;
    if (_.isEqual(this.fromDate, this.toDate)) {
      tD = (new Date(this.toDate.year,
        this.toDate.month - 1, this.toDate.day + 1)).toISOString();
    } else {
      tD = (new Date(this.toDate.year,
        this.toDate.month - 1, this.toDate.day)).toISOString();
    }
    const fD = (new Date(this.fromDate.year,
      this.fromDate.month - 1, this.fromDate.day)).toISOString();

    this.params = {
      'startDate': fD,
      'endDate': tD
    };

    this.params.inspectionTypeId = this.inspectionType ? this.inspectionType : '';
    if (this.storageService.getObject('clientData')) {
      const clientData = this.storageService.getObject('clientData');
      this.userId = clientData.id;
    }

    this.analyticService.getAnalyticsData(this.params).subscribe(response => {
      this.spinnerService.change(false);
      // const analyticsObservable = this.analyticService.getAnalyticsData(this.params)
      // .subscribe(response => {
      //   this.spinnerService.change(false);
      //   this.total_assignment = response.meta.total;
      //   analyticsObservable.unsubscribe();
      // }, err => {
      // this.spinnerService.change(false);
      // });
    });
  }
}
