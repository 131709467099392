import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHour'
})
export class SecondsToHourPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? `${(value / 3600).toFixed(2)}h` : '0h';
  }

}
