import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe } from './fileSize/file-size.pipe';
import { SecondsToHourPipe } from './secondsToHour/seconds-to-hour.pipe';
import { ConcatPipe } from './concat.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FileSizePipe,
    SecondsToHourPipe,
    ConcatPipe,
    FilterPipe
  ],
  providers: [
  ],
  exports: [
    FileSizePipe,
    SecondsToHourPipe,
    ConcatPipe,
    FilterPipe
  ]
})
export class PipeModule { }
