import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concat'
})
export class ConcatPipe implements PipeTransform {

  result = '';
  transform(value: any, args?: any): any {
    value.map((res) => {
      if (this.result === '') {
        this.result = res.description;
      } else {
        this.result = this.result + ', ' + res.description;
      }
    });
    return this.result;
  }

}
